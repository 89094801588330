import Layout from "../components/layout"
import SEO from "../components/seo"
import React from "react"
import Reviews from "../components/reviews"
import VirtualQuoteForm from "../components/virtual-quote-form"

const CabinetVirtualQuotePage = ({ location }) => {
  const shortLeadInfo =
    location && location.state
      ? {
          name: location.state.name || "",
          phone_number: location.state.phone_number || "",
          email: location.state.email || "",
        }
      : null

  return (
    <Layout>
      <SEO
        title="Cabinet Painting Virtual Quote"
        description="Pivot Painters - Cabinet Painting Virtual Quote"
      />
      <VirtualQuoteForm shortLeadInfo={shortLeadInfo} />
    </Layout>
  )
}

export default CabinetVirtualQuotePage
