import { navigate } from "gatsby"
import React, { useState, useRef } from "react"
import { useForm } from "react-hook-form"
import * as FormStyles from "../styles/oneScreenBookingForm.module.scss"
import imageCompression from "browser-image-compression"

const VirtualQuoteForm = ({ shortLeadInfo }) => {
  const [quoteState, setQuoteState] = useState({
    formStep: 1,

    name: shortLeadInfo?.name || "",
    phone_number: shortLeadInfo?.phone_number || "",
    email: shortLeadInfo?.email || "",
    address: "",
    city: "",
    zip_code: "",
    notes: "",
    pref_method: "",

    numberOfDoors: "",
    numberOfDrawers: "",
    additionalInteriorWork: null,
    hasDamage: null,
    changingHardware: null,
    hardwareDifferentSize: null,
    colorChoice: "",

    wholeRoom: [],
    upperRow: [],
    lowerRow: [],
    glassDoors: [],
    island: [],
    additionalPhotos: [],
  })
  const wholeRoomRef = useRef(null)
  const upperRowRef = useRef(null)
  const lowerRowRef = useRef(null)
  const glassDoorsRef = useRef(null)
  const islandRef = useRef(null)
  const additionPhotosRef = useRef(null)

  const { handleSubmit, errors, setError, clearErrors, register } = useForm()

  const handleInputChange = e => {
    const key = e.target.name
    const value = e.target.value
    if (key === "pref_method" && value) clearErrors("pref_method")
    else if (key === "additionalInteriorWork" && value)
      clearErrors("additionalInteriorWork")
    else if (key === "hasDamage" && value) clearErrors("hasDamage")
    else if (key === "hardwareDifferentSize" && value)
      clearErrors("hardwareDifferentSize")
    setQuoteState({
      ...quoteState,
      [key]: value,
    })
  }

  const handleChangeStep = () => {
    if (quoteState.formStep === 1 && !quoteState.pref_method) {
      setError("pref_method", {
        type: "manual",
        message: "Please Select A Preferred Method",
      })
      return
    }

    if (quoteState.formStep === 2 && !quoteState.additionalInteriorWork) {
      setError("additionalInteriorWork", {
        type: "manual",
        message: "Please Select An Option",
      })
      return
    } else if (quoteState.formStep === 2 && !quoteState.hasDamage) {
      setError("hasDamage", {
        type: "manual",
        message: "Please Select An Option",
      })
      return
    } else if (
      quoteState.formStep === 2 &&
      quoteState.changingHardware === "true" &&
      !quoteState.hardwareDifferentSize
    ) {
      setError("hardwareDifferentSize", {
        type: "manual",
        message: "Please Select An Option",
      })
      return
    }

    setQuoteState(prevState => ({
      ...prevState,
      formStep: prevState.formStep + 1,
    }))
  }

  const showError = _fieldName => {
    {
      let fieldError = errors[_fieldName]
      if (!fieldError) return
      return (
        fieldError && (
          <div
            style={{
              color: "red",
              padding: 5,
              fontSize: "16px",
            }}
          >
            {fieldError.message}
          </div>
        )
      )
    }
  }

  const handleFileUpload = (e, fileGroup, isDnd) => {
    e.preventDefault()
    if (!e.dataTransfer && !e.dataTransfer?.files && !e.currentTarget.files)
      return

    clearErrors(fileGroup)

    let files = isDnd ? e.dataTransfer.files : e.currentTarget.files

    const compressionOptions = {
      maxSizeMB: 0.25,
      maxWidthOrHeight: 768,
    }

    let compressedFiles = []

    let fileCounter = 0

    Array.from(files).forEach(file => {
      // console.log(`originalFile size ${file.size / 1024 / 1024} MB`)
      imageCompression(file, compressionOptions).then(compressedFile => {
        // // console.log(
        // //   `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
        // // )
        compressedFiles.push(compressedFile)
        fileCounter++
        if (fileCounter === files.length) {
          let updatedList = [...quoteState[fileGroup], ...compressedFiles]

          setQuoteState(prevState => ({
            ...prevState,
            [fileGroup]: updatedList,
          }))
        }
      })
    })
  }

  const submitRequest = e => {
    e.preventDefault()

    if (quoteState.wholeRoom.length === 0) {
      setError("wholeRoom", {
        type: "manual",
        message: "Please Add Photos",
      })
      return
    } else if (quoteState.upperRow.length === 0) {
      setError("upperRow", {
        type: "manual",
        message: "Please Add Photos",
      })
      return
    } else if (quoteState.lowerRow.length === 0) {
      setError("lowerRow", {
        type: "manual",
        message: "Please Add Photos",
      })
      return
    }

    if (window.gtag) {
      window.gtag("event", "conversion", {
        send_to: "AW-10865682082/yugPCKGB5KgDEKLNlL0o",
      })
    }

    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq("track", "Lead", { currency: "USD" })
      }
    }

    const url = `${process.env.GATSBY_ZONUM_API_URL}/cabinet-virtual-quote`

    var data = new FormData()

    data.append("name", quoteState.name)
    data.append("phone_number", quoteState.phone_number)
    data.append("email", quoteState.email)
    data.append("address_1", quoteState.address)
    data.append("city", quoteState.city)
    data.append("zip_code", quoteState.zip_code)
    data.append("notes", quoteState.notes)
    data.append("numberOfDoors", quoteState.numberOfDoors)
    data.append("numberOfDrawers", quoteState.numberOfDrawers)
    data.append("additionalInteriorWork", quoteState.additionalInteriorWork)
    data.append("hasDamage", quoteState.hasDamage)
    data.append("changingHardware", quoteState.changingHardware)
    data.append("hardwareDifferentSize", quoteState.hardwareDifferentSize)
    data.append("colorChoice", quoteState.colorChoice)
    data.append("pref_method", quoteState.pref_method)
    data.append("status", "Estimate Requested")
    data.append("isVirtual", true)

    for (const image of quoteState.wholeRoom) {
      data.append("wholeRoom", image, image.name)
    }
    for (const image of quoteState.upperRow) {
      data.append("upperRow", image, image.name)
    }
    for (const image of quoteState.lowerRow) {
      data.append("lowerRow", image, image.name)
    }
    for (const image of quoteState.island) {
      data.append("island", image, image.name)
    }
    for (const image of quoteState.glassDoors) {
      data.append("glassDoors", image, image.name)
    }
    for (const image of quoteState.additionalPhotos) {
      data.append("additionalPhotos", image, image.name)
    }

    let fetchData = {
      method: "POST",
      body: data,
      headers: {
        Auth: "NONE",
      },
    }

    if (window && window.localStorage) {
      window.localStorage.setItem(
        "infoForSignIn",
        JSON.stringify({
          userInfo: { email: quoteState.email, name: quoteState.name },
        })
      )
    }

    navigate("/thank-you", { state: { prevPath: "cabinet-virtual-quote" } })

    fetch(url, fetchData).then(function () {})
  }

  const removeImage = (fileGroup, fileName) => {
    let updatedList = quoteState[fileGroup].filter(
      file => file.name !== fileName
    )
    setQuoteState(prevState => ({
      ...prevState,
      [fileGroup]: updatedList,
    }))
  }

  return (
    <section className={FormStyles.formContainer}>
      <div className={FormStyles.howItWorksContainer}>
        <p className={FormStyles.howItWorksHeader}>
          How A Virtual Estimate Works
        </p>
        <div className={FormStyles.howItWorksStepContainer}>
          <div className={FormStyles.howItWorksStep}>
            <i className="fas fa-camera"></i>
            <p>Snap some photos of your cabinets and fill out the form below</p>
          </div>
          <div className={FormStyles.howItWorksStep}>
            <i className="fas fa-envelope-open-text"></i>
            <p>
              We will review your request and write up a quote to email to you
            </p>
          </div>
          <div className={FormStyles.howItWorksStep} style={{ border: "none" }}>
            <i className="fas fa-calendar-day"></i>
            <p>
              If everything looks good, then we will work together to schedule
              your project!
            </p>
          </div>
        </div>
      </div>

      <div
        className={`${FormStyles.stepPane} ${
          quoteState.formStep === 1 ? FormStyles.active : ""
        } ${quoteState.formStep > 1 ? FormStyles.collapsed : ""}`}
      >
        <div className={FormStyles.stepTitle}>
          <span className={FormStyles.stepNumber}>1</span>
          <span>Who Is Requesting A Quote?</span>
          {quoteState.formStep > 1 && (
            <div className={FormStyles.collapsedChevronContainer}>
              <i
                className="fas fa-chevron-down"
                onClick={() =>
                  setQuoteState(prevState => ({ ...prevState, formStep: 1 }))
                }
              ></i>
            </div>
          )}
          <div className={FormStyles.stepPaneContent}>
            {quoteState.formStep === 1 && (
              <form onSubmit={handleSubmit(handleChangeStep)}>
                <div className={FormStyles.parentInputGroup}>
                  <div className={FormStyles.inputGroup}>
                    <label className={FormStyles.inputGroupLabel}>
                      Your Name
                      <span className={FormStyles.required}> *</span>
                    </label>
                    <div>
                      <input
                        type="text"
                        name="name"
                        value={quoteState.name}
                        onChange={handleInputChange}
                        ref={register({
                          required: {
                            value: true,
                            message: "Please Enter Your Name",
                          },
                        })}
                      />
                      {showError("name")}
                    </div>
                  </div>
                  <div className={FormStyles.inputGroup}>
                    <label className={FormStyles.inputGroupLabel}>
                      Phone (Used To Confirm Appointment)
                      <span className={FormStyles.required}> *</span>
                    </label>
                    <div>
                      <input
                        type="text"
                        name="phone_number"
                        value={quoteState.phone_number}
                        onChange={handleInputChange}
                        ref={register({
                          required: {
                            value: true,
                            message: "Please Enter Your Phone Number",
                          },
                        })}
                      />
                      {showError("phone_number")}
                    </div>
                  </div>
                </div>
                <div className={FormStyles.parentInputGroup}>
                  <div className={FormStyles.inputGroup}>
                    <label className={FormStyles.inputGroupLabel}>
                      Email (Where Can We Send Your Final Quote?)
                      <span className={FormStyles.required}> *</span>
                    </label>
                    <div>
                      <input
                        type="email"
                        name="email"
                        value={quoteState.email}
                        onChange={handleInputChange}
                        ref={register({
                          required: {
                            value: true,
                            message: "Please Enter Your Email",
                          },
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Invalid Email Address",
                          },
                        })}
                      />
                      {showError("email")}
                    </div>
                  </div>
                  <div className={FormStyles.inputGroup}>
                    <label className={FormStyles.inputGroupLabel}>
                      What Is The Project Address?
                      <span className={FormStyles.required}> *</span>
                    </label>
                    <div>
                      <input
                        type="text"
                        name="address"
                        value={quoteState.address}
                        onChange={handleInputChange}
                        ref={register({
                          required: {
                            value: true,
                            message: "Please Enter Your Address",
                          },
                        })}
                      />
                      {showError("address")}
                    </div>
                  </div>
                </div>
                <div className={FormStyles.parentInputGroup}>
                  <div className={FormStyles.inputGroup}>
                    <label className={FormStyles.inputGroupLabel}>
                      City
                      <span className={FormStyles.required}> *</span>
                    </label>
                    <div>
                      <input
                        type="text"
                        name="city"
                        value={quoteState.city}
                        onChange={handleInputChange}
                        ref={register({
                          required: {
                            value: true,
                            message: "Please Enter Your City",
                          },
                        })}
                      />
                      {showError("city")}
                    </div>
                  </div>
                  <div className={FormStyles.inputGroup}>
                    <label className={FormStyles.inputGroupLabel}>
                      Zip Code
                      <span className={FormStyles.required}> *</span>
                    </label>
                    <div>
                      <input
                        type="text"
                        name="zip_code"
                        value={quoteState.zip_code}
                        onChange={handleInputChange}
                        ref={register({
                          required: {
                            value: true,
                            message: "Please Enter Your Zip Code",
                          },
                        })}
                      />
                      {showError("zip_code")}
                    </div>
                  </div>
                </div>
                <div className={FormStyles.radioGroupContainer}>
                  <label className={FormStyles.radioGroupLabel}>
                    Preferred Method of Communication
                    <span className={FormStyles.required}> *</span>
                  </label>
                  <div className={FormStyles.radioGroup}>
                    <div className={FormStyles.radioOption}>
                      <input
                        type="radio"
                        name="pref_method"
                        value="text"
                        onChange={handleInputChange}
                      />
                      <label>Text</label>
                    </div>
                    <div className={FormStyles.radioOption}>
                      <input
                        type="radio"
                        name="pref_method"
                        value="email"
                        onChange={handleInputChange}
                      />
                      <label>Email</label>
                    </div>
                    <div className={FormStyles.radioOption}>
                      <input
                        type="radio"
                        name="pref_method"
                        value="call"
                        onChange={handleInputChange}
                      />
                      <label>Call</label>
                    </div>
                  </div>
                  {showError("pref_method")}
                </div>
                <button
                  onClick={handleSubmit(handleChangeStep)}
                  className={FormStyles.continueButton}
                  type="submit"
                >
                  Continue
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
      <div
        className={`${FormStyles.stepPane} ${
          quoteState.formStep === 2 ? FormStyles.active : ""
        } ${quoteState.formStep > 1 ? FormStyles.collapsed : ""}`}
      >
        <div className={FormStyles.stepTitle}>
          <span className={FormStyles.stepNumber}>2</span>
          <span>Tell Us About Your Cabinets</span>
          {quoteState.formStep > 2 && (
            <div className={FormStyles.collapsedChevronContainer}>
              <i
                className="fas fa-chevron-down"
                onClick={() =>
                  setQuoteState(prevState => ({ ...prevState, formStep: 2 }))
                }
              ></i>
            </div>
          )}
        </div>
        <div className={FormStyles.stepPaneContent}>
          {quoteState.formStep === 2 && (
            <form onSubmit={handleSubmit(handleChangeStep)}>
              <div className={FormStyles.inputGroup}>
                <label className={FormStyles.inputGroupLabel}>
                  Please Provide Some Details About Your Project
                  <span className={FormStyles.required}> *</span>
                </label>
                <div>
                  <textarea
                    type="text"
                    name="notes"
                    value={quoteState.notes}
                    onChange={handleInputChange}
                    ref={register({
                      required: {
                        value: true,
                        message:
                          "Please Give A Brief Description About Your Project",
                      },
                    })}
                  />
                  {showError("notes")}
                </div>
              </div>
              <div className={FormStyles.parentInputGroup}>
                <div className={FormStyles.inputGroup}>
                  <label className={FormStyles.inputGroupLabel}>
                    Number of Cabinet Doors
                    <span className={FormStyles.required}> *</span>
                  </label>
                  <div>
                    <input
                      type="number"
                      name="numberOfDoors"
                      value={quoteState.numberOfDoors}
                      onChange={handleInputChange}
                      ref={register({
                        required: {
                          value: true,
                          message: "Please Enter The Number of Cabinet Doors",
                        },
                      })}
                    />
                    {showError("numberOfDoors")}
                  </div>
                </div>
                <div className={FormStyles.inputGroup}>
                  <label className={FormStyles.inputGroupLabel}>
                    Number of Cabinet Drawers
                    <span className={FormStyles.required}> *</span>
                  </label>
                  <div>
                    <input
                      type="number"
                      name="numberOfDrawers"
                      value={quoteState.numberOfDrawers}
                      onChange={handleInputChange}
                      ref={register({
                        required: {
                          value: true,
                          message: "Please Enter The Number of Cabinet Drawers",
                        },
                      })}
                    />
                    {showError("numberOfDrawers")}
                  </div>
                </div>
              </div>
              <div className={FormStyles.parentInputGroup}>
                <div className={FormStyles.inputGroup}>
                  <label className={FormStyles.inputGroupLabel}>
                    Do You Have Any Additional Interior Painting Needs (Walls,
                    Ceilings, Trim)?
                    <span className={FormStyles.required}> *</span>
                  </label>
                  <div>
                    <select
                      name="additionalInteriorWork"
                      value={quoteState.additionalInteriorWork}
                      onChange={handleInputChange}
                    >
                      <option hidden selected>
                        Select One
                      </option>
                      <option value="false">No</option>
                      <option value="true">Yes</option>
                      <option value="notSure">Not Sure</option>
                    </select>
                    {showError("additionalInteriorWork")}
                    {quoteState.additionalInteriorWork &&
                      quoteState.additionalInteriorWork === "true" && (
                        <div
                          style={{
                            padding: 5,
                            fontSize: "14px",
                          }}
                        >
                          ** Please Make Sure To Include Details About The
                          Interior Painting Above
                        </div>
                      )}
                  </div>
                </div>
                <div className={FormStyles.inputGroup}>
                  <label className={FormStyles.inputGroupLabel}>
                    What Color(s) Are You Looking To Have Your Cabinets Painted?
                  </label>
                  <div>
                    <input
                      type="text"
                      name="colorChoice"
                      value={quoteState.colorChoice}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className={FormStyles.parentInputGroup}>
                <div className={FormStyles.inputGroup}>
                  <label className={FormStyles.inputGroupLabel}>
                    Do Your Cabinets Have Any Damage?
                    <span className={FormStyles.required}> *</span>
                  </label>
                  <div>
                    <select
                      name="hasDamage"
                      value={quoteState.hasDamage}
                      onChange={handleInputChange}
                    >
                      <option hidden selected>
                        Select One
                      </option>
                      <option value="false">No</option>
                      <option value="true">Yes</option>
                      <option value="notSure">Not Sure</option>
                    </select>
                    {showError("hasDamage")}
                  </div>
                </div>
                <div className={FormStyles.inputGroup}>
                  <label className={FormStyles.inputGroupLabel}>
                    Would You Like To Change Cabinet Hardware
                    (Handles/Knobs/Pulls)?
                  </label>
                  <div>
                    <select
                      name="changingHardware"
                      value={quoteState.changingHardware}
                      onChange={handleInputChange}
                    >
                      <option hidden selected>
                        Select One
                      </option>
                      <option value="false">No</option>
                      <option value="true">Yes</option>
                      <option value="notSure">Not Sure</option>
                    </select>
                    {showError("changingHardware")}
                  </div>
                </div>
              </div>
              {quoteState.changingHardware === "true" && (
                <div className={FormStyles.parentInputGroup}>
                  <div className={FormStyles.inputGroup}>
                    <label className={FormStyles.inputGroupLabel}>
                      Is The New Hardware A Different Size Than What Is
                      Currently Installed?
                      <span className={FormStyles.required}> *</span>
                    </label>
                    <div>
                      <select
                        name="hardwareDifferentSize"
                        value={quoteState.hardwareDifferentSize}
                        onChange={handleInputChange}
                      >
                        <option hidden selected>
                          Select One
                        </option>
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                        <option value="notSure">Not Sure</option>
                      </select>
                      {showError("hardwareDifferentSize")}
                    </div>
                  </div>
                </div>
              )}
              <button
                onClick={handleSubmit(handleChangeStep)}
                className={FormStyles.continueButton}
                type="submit"
              >
                Continue
              </button>
            </form>
          )}
        </div>
      </div>
      <div
        className={`${FormStyles.stepPane} ${
          quoteState.formStep === 3 ? FormStyles.active : ""
        } ${quoteState.formStep > 1 ? FormStyles.collapsed : ""}`}
      >
        <div className={FormStyles.stepTitle}>
          <span className={FormStyles.stepNumber}>3</span>
          <span>Let's See Some Pictures Of Your Cabinets</span>
        </div>
        <div className={FormStyles.stepPaneContent}>
          {quoteState.formStep === 3 && (
            <form onSubmit={handleSubmit(handleChangeStep)}>
              <div className={FormStyles.parentInputGroup}>
                <div className={FormStyles.inputGroup}>
                  <label className={FormStyles.inputGroupLabel}>
                    Picture(s) Of The Entire Room
                    <span className={FormStyles.required}> *</span>
                  </label>
                  <div
                    className={FormStyles.fileContainer}
                    onDrop={e => handleFileUpload(e, "wholeRoom", true)}
                    onDragOver={e => {
                      e.stopPropagation()
                      e.preventDefault()
                    }}
                  >
                    <p>Drop Files Here Or</p>
                    <label
                      className={FormStyles.uploadFileButton}
                      onClick={e => wholeRoomRef.current.click()}
                    >
                      Select
                    </label>
                    <input
                      type="file"
                      name="wholeRoom"
                      onChange={e => handleFileUpload(e, "wholeRoom", false)}
                      accept="image/png, image/jpeg"
                      multiple
                      ref={wholeRoomRef}
                      style={{ display: "none" }}
                    />
                  </div>
                  {quoteState.wholeRoom &&
                    quoteState.wholeRoom.map((pic, index) => (
                      <p key={index} className={FormStyles.uploadedFile}>
                        <i
                          className="fas fa-times-circle"
                          onClick={e => removeImage("wholeRoom", pic.name)}
                        ></i>
                        {pic.name}
                      </p>
                    ))}
                  {showError("wholeRoom")}
                </div>
                <div className={FormStyles.inputGroup}>
                  <label className={FormStyles.inputGroupLabel}>
                    Picture(s) Of The Upper Row
                    <span className={FormStyles.required}> *</span>
                  </label>
                  <div
                    className={FormStyles.fileContainer}
                    onDrop={e => handleFileUpload(e, "upperRow", true)}
                    onDragOver={e => {
                      e.stopPropagation()
                      e.preventDefault()
                    }}
                  >
                    <p>Drop Files Here Or</p>
                    <label
                      className={FormStyles.uploadFileButton}
                      onClick={e => upperRowRef.current.click()}
                    >
                      Select
                    </label>
                    <input
                      type="file"
                      name="upperRow"
                      onChange={e => handleFileUpload(e, "upperRow", false)}
                      accept="image/png, image/jpeg"
                      multiple
                      ref={upperRowRef}
                      style={{ display: "none" }}
                    />
                  </div>
                  {quoteState.upperRow &&
                    quoteState.upperRow.map((pic, index) => (
                      <p key={index} className={FormStyles.uploadedFile}>
                        <i
                          className="fas fa-times-circle"
                          onClick={e => removeImage("upperRow", pic.name)}
                        ></i>
                        {pic.name}
                      </p>
                    ))}
                  {showError("upperRow")}
                </div>
                <div className={FormStyles.inputGroup}>
                  <label className={FormStyles.inputGroupLabel}>
                    Picture(s) Of The Lower Row
                    <span className={FormStyles.required}> *</span>
                  </label>
                  <div
                    className={FormStyles.fileContainer}
                    onDrop={e => handleFileUpload(e, "lowerRow", true)}
                    onDragOver={e => {
                      e.stopPropagation()
                      e.preventDefault()
                    }}
                  >
                    <p>Drop Files Here Or</p>
                    <label
                      className={FormStyles.uploadFileButton}
                      onClick={e => lowerRowRef.current.click()}
                    >
                      Select
                    </label>
                    <input
                      type="file"
                      name="lowerRow"
                      onChange={e => handleFileUpload(e, "lowerRow", false)}
                      accept="image/png, image/jpeg"
                      multiple
                      ref={lowerRowRef}
                      style={{ display: "none" }}
                    />
                  </div>
                  {quoteState.lowerRow &&
                    quoteState.lowerRow.map((pic, index) => (
                      <p key={index} className={FormStyles.uploadedFile}>
                        <i
                          className="fas fa-times-circle"
                          onClick={e => removeImage("lowerRow", pic.name)}
                        ></i>
                        {pic.name}
                      </p>
                    ))}
                  {showError("lowerRow")}
                </div>
              </div>
              <div className={FormStyles.parentInputGroup}>
                <div className={FormStyles.inputGroup}>
                  <label className={FormStyles.inputGroupLabel}>
                    Picture(s) Of Any Kitchen Islands
                  </label>
                  <div
                    className={FormStyles.fileContainer}
                    onDrop={e => handleFileUpload(e, "island", true)}
                    onDragOver={e => {
                      e.stopPropagation()
                      e.preventDefault()
                    }}
                  >
                    <p>Drop Files Here Or</p>
                    <label
                      className={FormStyles.uploadFileButton}
                      onClick={e => islandRef.current.click()}
                    >
                      Select
                    </label>
                    <input
                      type="file"
                      name="island"
                      onChange={e => handleFileUpload(e, "island", false)}
                      accept="image/png, image/jpeg"
                      multiple
                      ref={islandRef}
                      style={{ display: "none" }}
                    />
                  </div>
                  {quoteState.island &&
                    quoteState.island.map((pic, index) => (
                      <p key={index} className={FormStyles.uploadedFile}>
                        <i
                          className="fas fa-times-circle"
                          onClick={e => removeImage("island", pic.name)}
                        ></i>
                        {pic.name}
                      </p>
                    ))}
                  {showError("island")}
                </div>
                <div className={FormStyles.inputGroup}>
                  <label className={FormStyles.inputGroupLabel}>
                    Picture(s) Of Any Cabinets With Glass Doors
                  </label>
                  <div
                    className={FormStyles.fileContainer}
                    onDrop={e => handleFileUpload(e, "glassDoors", true)}
                    onDragOver={e => {
                      e.stopPropagation()
                      e.preventDefault()
                    }}
                  >
                    <p>Drop Files Here Or</p>
                    <label
                      className={FormStyles.uploadFileButton}
                      onClick={e => glassDoorsRef.current.click()}
                    >
                      Select
                    </label>
                    <input
                      type="file"
                      name="glassDoors"
                      onChange={e => handleFileUpload(e, "glassDoors", false)}
                      accept="image/png, image/jpeg"
                      multiple
                      ref={glassDoorsRef}
                      style={{ display: "none" }}
                    />
                  </div>
                  {quoteState.glassDoors &&
                    quoteState.glassDoors.map((pic, index) => (
                      <p key={index} className={FormStyles.uploadedFile}>
                        <i
                          className="fas fa-times-circle"
                          onClick={e => removeImage("glassDoors", pic.name)}
                        ></i>
                        {pic.name}
                      </p>
                    ))}
                  {showError("glassDoors")}
                </div>
                <div className={FormStyles.inputGroup}>
                  <label className={FormStyles.inputGroupLabel}>
                    Any Additional Photos
                  </label>
                  <div
                    className={FormStyles.fileContainer}
                    onDrop={e => handleFileUpload(e, "additionalPhotos", true)}
                    onDragOver={e => {
                      e.stopPropagation()
                      e.preventDefault()
                    }}
                  >
                    <p>Drop Files Here Or</p>
                    <label
                      className={FormStyles.uploadFileButton}
                      onClick={e => additionPhotosRef.current.click()}
                    >
                      Select
                    </label>
                    <input
                      type="file"
                      name="additionalPhotos"
                      onChange={e =>
                        handleFileUpload(e, "additionalPhotos", false)
                      }
                      accept="image/png, image/jpeg"
                      multiple
                      ref={additionPhotosRef}
                      style={{ display: "none" }}
                    />
                  </div>
                  {quoteState.additionalPhotos &&
                    quoteState.additionalPhotos.map((pic, index) => (
                      <p key={index} className={FormStyles.uploadedFile}>
                        <i
                          className="fas fa-times-circle"
                          onClick={e =>
                            removeImage("additionalPhotos", pic.name)
                          }
                        ></i>
                        {pic.name}
                      </p>
                    ))}
                  {showError("additionalPhotos")}
                </div>
              </div>
              <button
                onClick={submitRequest}
                className={FormStyles.continueButton}
                type="submit"
              >
                Send
              </button>
            </form>
          )}
        </div>
      </div>
    </section>
  )
}

export default VirtualQuoteForm
